<template>
  <base-section id="products-detail">
    <v-container class="mt-n16">
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <template>
          <v-card
            class="mx-auto"
            v-for="data in posts"
            :key="data.web_product_id"
            flat
          >
            <v-row justify="space-between">
            <v-col 
              cols="12"
              md="8"
            >
            <v-img
              :src="data.web_product_src_url"
              max-height="300"
              max-width="100%"
              :alt="$route.params.url"
            ></v-img>
            <v-row
            no-gutters
          >
            <v-col
              v-for="data in benefits"
              :key="data.title"
            >
              <v-card
                outlined
              >
                <v-card-actions>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon large color="primary">{{ data.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ data.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ data.subtitle }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
            </v-col>
            <v-col
            cols ="12"
            md   = "4">
            <v-card
            outlined>
            <template>
            <v-card-text>
              <span class="body-2">Hubungi Customer Solution kami untuk detail produk ini melalui Whatsapp</span>
              <div>
                <v-btn color="secondary" outlined block large class="title" @click="Whatsapp"><v-icon>mdi-whatsapp</v-icon>081X XXX XXXX</v-btn>
              </div>
              </v-card-text>
            </template>
            </v-card>
            </v-col>
            <v-col 
              cols="12"
              md="8"
            >
            <div class="headline my-5 primary--text"> {{ title }} </div>
            <div class="body-2 accent--text">{{ FormatDateFullOnlyDate(data.web_product_cdate) }}</div>
            <small>Bagikan : </small>
            <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :url="sharing.url"
                :title="sharing.title"
                :hashtags="sharing.hashtags"
              >
                <v-icon :color="network.color">{{ network.icon }}</v-icon>
              </ShareNetwork>
            <v-alert
              border="top"
              colored-border
              color="primary"
              class="grey lighten-4 body-2 text-justify"
            >
            <div itemscope v-html="data.web_product_detail"></div>
            </v-alert>
            </v-col>
            <v-col
              cols = "12"
              md   = "4"
            >
            F.A.Q
            <v-row justify="center">
              <v-expansion-panels
               accordion
               focusable
               >
                <v-expansion-panel
                  v-for="data in faq"
                  :key="data.faq_id"
                >
                  <v-expansion-panel-header>Q: {{ data.faq_question }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    A: {{ data.faq_answer }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
            <v-row justify = "center">

            </v-row>
            </v-col>
            </v-row>
            <v-card-actions>
            </v-card-actions>
          </v-card>
        </template>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet v-model="bottom_sheet">
        <v-list class="pt-0">
            <v-subheader class="primary white--text" @click="bottom_sheet = false">Plat Nomor <v-spacer></v-spacer><span class="cursor">tutup</span></v-subheader>
             <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <div class="scrollable">
            <v-list-item
            v-for="platnomor in platnomors"
            :key="platnomor.gendtab_id"
            @click="GetRegisterVeh(platnomor.gendtab_id)"
            >
            <v-list-item-title class="text-center">{{ platnomor.gendtab_id }}</v-list-item-title>
            </v-list-item>
            </div>
        </v-list>
      </v-bottom-sheet>
      <v-snackbar
        v-model="snackbar.value"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn
          color="fourth"
          text
          v-bind="attrs"
          @click="snackbar.value = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProductsDetail',
    data: () => ({
          title: '',
          share: false,
          posts: [],
          benefits: [],
          faq: [],
          sharing: {
            url: '',
            title: '',
            hashtags: 'orionpenjaminanindonesia,penjaminanaman',
          },
          networks: [
            { network: 'email', name: 'Email', icon: 'mdi-email', color: 'primary' },
            { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: 'primary' },
            { network: 'telegram', name: 'Telegram', icon: 'mdi-telegram', color: 'primary' },
            { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: 'primary' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: 'primary' },
          ],
          prefix: 'B',
          bottom_sheet: false,
          platnomors: [],
          snackbar: {
            value: false,
            text: ''
          },
          loading: false
      }),
    beforeRouteLeave (to, from, next) {
        if (this.bottom_sheet) {
            this.bottom_sheet = false
            next(false)
        } else {
            next()
        }
    },
    created () {
      this.List()
    },
    watch: {
        "$route.params.url"(val) {
          console.log(val)
          this.List();
        },
        title: function (val, old) {
            console.log(old)
            document.title     = val
            this.sharing.title = this.$functions.AppURL() +""+ this.$router.currentRoute.path
        }
    },
    methods: {
      List () {
        let data = [
                      {'web_product_id':'0101','web_product_name':'Penjaminan Kredit Multiguna','web_product_src_url':'','web_product_detail':'Jaminan Kredit yang diberikan Bank kepada Nasabah untuk memenuhi berbagai kebutuhan dengan sumber pengembalian adalah penghasilan tetap/gaji Nasabah'},
                      {'web_product_id':'0102','web_product_name':'Penjaminan Kredit Pemilikan Rumah','web_product_src_url':'','web_product_detail':'Jaminan Kredit yang diberikan Bank kepada Nasabah untuk pemilikan rumah baik produk komersil Bank maupun program FLPP'},
                      {'web_product_id':'0103','web_product_name':'Penjaminan Kredit Mikro','web_product_src_url':'','web_product_detail':'Jaminan Kredit yang diberikan Bank kepada Nasabah untuk mendukung kelancaran kegiatan usaha bagi Pelaku Usaha Mikro baik kredit modal kerja/investasi. Plafond kredit sd 250 Juta atau sesuai ketentuan Bank'},
                      {'web_product_id':'0104','web_product_name':'Penjaminan Kredit Umum','web_product_src_url':'','web_product_detail':'Jaminan Kredit yang diberikan Bank kepada Nasabah untuk mendukung kelancaran kegiatan usaha bagi Pelaku Usaha dengan plafond diatas Kredit Mikro'},
                      {'web_product_id':'0105','web_product_name':'Penjaminan Konstruksi/Pengadaan Barang & Jasa','web_product_src_url':'','web_product_detail':'Jaminan Kredit yang diberikan Bank kepada Nasabah dalam rangka modal kerja untuk menyelesaikan pekerjaan konstruksi/pengadaan barang atau jasa sesuai dengan kontrak dengan Pemberi Kerja'},
                      {'web_product_id':'0106','web_product_name':'Penjaminan Supply Chain Financing','web_product_src_url':'','web_product_detail':'Jaminan Kredit yang diberikan Bank kepada Nasabah dalam rangka pengalihan penyelesaian piutang atau tagihan dari Nasabah kepada Bank yang kemudian menagih piutang tersebut kepada Pemberi Kerja'},
                      {'web_product_id':'0201','web_product_name':'Surety Bond','web_product_src_url':'','web_product_detail':'Jaminan kepada Obligee atas risiko kegagalan/wanprestasi Principal dalam melaksanakan pekerjaan sesuai yang diperjanjikan dengan Obligee'},
                      {'web_product_id':'0202','web_product_name':'Kontra Bank Garansi','web_product_src_url':'','web_product_detail':'Jaminan sebagai kontra garansi atas fasilitas Bank Garansi yang diterbitkan oleh Bank kepada Terjamin'},
                      {'web_product_id':'0203','web_product_name':'Customs Bond','web_product_src_url':'','web_product_detail':'Jaminan atas fasilitas kepabeanan yang diberikan oleh Obligee/Dirjen Bea Cukai untuk menjamin risiko Principal apabila tidak dapat memenuhi kewajibannya dalam pungutan negara dalam rangka kepabeanan'},
                      ]
        let get = this
        this.posts = data.filter(function (el) {
            return el.web_product_id === get.$route.params.url
        })
        this.title    = this.posts[0].web_product_name
        this.Benefits(this.posts[0].web_product_id)
        // this.loading  = true
        // let title = this.$route.params.url
        // let formdata = {
        //       web_product_id: title
        // }
        // let param      = this.$functions.ParamPOST(formdata)
        // this.$axios.post(this.$functions.UrlPOST('apiWebProductList'),param,{
        // headers: {
        //     'Content-Type': 'text/plain; charset=ISO-8859-1'
        // }
        // })
        // .then(response => {
        //   let feedback    = response.data
        //   if (feedback.length > 0) {
        //     if (feedback[0].feedback === 'Y') {
        //       this.posts    = feedback
        //       this.title    = this.posts[0].web_product_name
        //       this.loading  = false
        //       this.Benefits(this.posts[0].web_product_id)
        //       this.FAQ(this.posts[0].web_product_id)
        //     } else {
        //       this.snackbar.value = true
        //       this.snackbar.text  = feedback[0].feedback
        //     }
        //   }
        // })
        // .catch(e => {
        //     this.snackbar.value = true
        //     this.snackbar.text  = e
        //     this.loading  = false
        // })
      },
      Benefits (id) {
        if (id !== '') {
          this.benefits = [
            {
          icon: 'mdi-professional-hexagon',
          title: 'Tenaga Ahli Profesional',
          subtitle: 'Memiliki tenaga ahli professional, bersertifikat dan berpengalaman di industri penjaminan.',
          },
          {
            icon: 'mdi-clock-fast',
            title: 'Proses Sertifikat dan Klaim Cepat',
            subtitle: 'Digital Proses, seluruh proses dari pembentukkan sertifikat dan klaim dilakukan melalui digital',
          },
          {
            icon: 'mdi-lock-check',
            title: 'Penjaminan Aman',
            subtitle: 'Keamanan Data, Sertifikat dan lain-lain',
          }
          ]
        }
      },
      FAQ (id) {
        let formdata = {
              faq_refno: id
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebFAQList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let data    = response.data
            if (data.length > 0) {
              if (data[0].feedback === 'Y') {
                this.faq = data
              } else {
                this.snackbar.value = true
                this.snackbar.text  = data[0].feedback
              }
            } else {
              this.faq = []
            }
        })
        .catch(e => {
            this.snackbar.value = true
            this.snackbar.text  = e
        })
      },
      RegisterVeh () {
        this.bottom_sheet = true
        this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple', `gendtab_actived=Y&gendtab_refid=V08&order_by=gendtab_id&order_type=ASC`))
        .then(response => {
            this.platnomors = response.data
        })
        .catch(e => {
            this.snackbar.value = true
            this.snackbar.text  = e
        })
      },
      GetRegisterVeh (id) {
        this.prefix = id
        this.bottom_sheet = false
        setTimeout(function(){
        document.getElementById('vehicle_reg').focus()
        }, 500)
      },
      FormatDateFullOnlyDate (date) {
        return this.$functions.FormatDateFullOnlyDate(date)
      },
      Whatsapp () {
        alert('Terimakasih')
        // window.open('https://api.whatsapp.com/send?phone=6281119061995&text=Assalamualaikum%20wr%20wb%2C%20saya%20ingin%20tahu%20produk%20'+this.title)
      }
    }
  }
</script>